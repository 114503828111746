<!--
 * @Author: lzh
 * @Date: 2022-12-14 16:03:34
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-12 17:01:20
 * @Description: file content
-->
<template>
  <div class="edit-ricePOS-image-text4-box">
    <div class="cell-b">
      <selfCell title="項-背景色">
        <el-color-picker
          v-model="configs.backgroundColor"
          @change="setValue('backgroundColor')"
          show-alpha
        ></el-color-picker>
      </selfCell>
      <selfCell title="項-圓背景色">
        <el-color-picker
          v-model="configs.startBGC"
          @change="setValue('startBGC')"
          show-alpha
        ></el-color-picker>
      </selfCell>

    </div>
    <div class="cell-b">
      <h3>項-上右下左间距</h3>
      <el-slider
        v-model="configs.paddingTop"
        show-input
        :max="500"
        @input="setValue('paddingTop')"
      >
      </el-slider>
      <el-slider
        v-model="configs.paddingRight"
        show-input
        :max="500"
        @input="setValue('paddingRight')"
      >
      </el-slider>
      <el-slider
        v-model="configs.paddingBottom"
        show-input
        :max="500"
        @input="setValue('paddingBottom')"
      >
      </el-slider>
      <el-slider
        v-model="configs.paddingLeft"
        :max="500"
        show-input
        @input="setValue('paddingLeft')"
      >
      </el-slider>
    </div>
    <div class="cell-b">
      <h3>項-標題配置</h3>
      <div class="cell-b">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}title`]"
          :placeholder="`請輸入標題-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}title`)"
          type="textarea"
          rows="3"
        ></el-input>
      </div>

      <h3>項-描述配置</h3>
      <div class="cell-b">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}desc`]"
          :placeholder="`請輸入描述-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}desc`)"
          type="textarea"
          rows="3"
        ></el-input>
      </div>
    </div>
    <div class="cell-b">
      <h3>項-icon配置</h3>
      <div class="icon-item-box">
        <div
          class="item"
          v-for="item,index in configs.items"
          :key="index"
        >
          <i
            v-if="isEdit"
            class="remove el-icon-delete"
            @click.stop="removeItem(index)"
          />
          <selfUpload
            style="width:48px;height:48px;"
            v-model="item.icon"
            type="default"
            :isPx="false"
            :radius="50"
            @change="setValue('items')"
          />
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="item[`${lItem.code=='HK'?'':lItem.code}title`]"
            :placeholder="`${lItem.name}`"
            @input="setValue('items')"
            type="textarea"
            rows="3"
          ></el-input>
        </div>
        <div
          class="item"
          @click="addIcon"
        >
          <i class="el-icon-plus"></i>
          <div class="icon-text">添加图标</div>
        </div>
      </div>
    </div>
    <div class="cell-b">
      <h3>項-图片配置</h3>
      <selfCell title="圖片類型">
        <el-select
          v-model="configs.type"
          placeholder="请选择"
          @change="setValue('type')"
        >
          <el-option
            label="大圖模式"
            :value="1"
          >
          </el-option>
          <el-option
            label="小圖模式"
            :value="2"
          >
          </el-option>
        </el-select>
      </selfCell>
      <selfUpload
        v-model="configs.image"
        type="default"
        @change="setValue('image')"
      />
      <el-input
        v-model="configs.alt"
        type="textarea"
        @change="setValue('alt')"
        placeholder="請輸入圖片描述"
      />
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
import { getByName } from '@/js/utils/default_config';
export default {
  name: "edit-ricePOS-image-text4",
  mixins: [langEditMixin],
  data () {
    return {
      isEdit: false
    }
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
  },
  props: {
    index: {
      default () {
        return '';
      },
    },
    configs: {
      default () {
        return {
        };
      },
    },
  },
  methods: {
    setValue (key) {
      if (key == 'src') key = 'items'
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
    removeItem (index) {
      let _ = this.configs.items;
      _.splice(index, 1);
      this.setValue('items');
    },
    addIcon () {
      let mod = getByName('RicePOSImageText4');
      let item = this.$util.object.clone(mod.configs.items[0]);
      let _ = this.configs.items;
      _.push(item);
      this.setValue('items')
    },
  }
}
</script>

<style lang="less" scoped>
.icon-item-box {
  display: flex;
  flex-wrap: wrap;
  .item {
    position: relative;
    margin-top: 20px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% / 3 - 20px);
    .remove {
      position: absolute;
      top: -12px;
      right: 16px;
      font-size: 20px;
      z-index: 10;
      z-index: 2;
      cursor: pointer;
      &:hover {
        color: var(--themeColor);
      }
    }
    /deep/ .el-input {
      margin-top: 10px;
      width: 80%;
    }
    &:nth-child(3n + 1) {
      margin-left: 0;
    }
    .el-icon-plus {
      width: 48px;
      height: 48px;
      border: 1px solid #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
    .icon-text {
      text-align: center;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      word-break: break-all;
      font-size: 14px;
      margin-top: 10px;
      cursor: pointer;
    }
    /deep/ .upload-box {
      .avatar-uploader {
        height: 100%;
        .el-upload--text {
          height: 100%;
          img {
            height: 100%;
          }
        }
      }
    }
  }
}
</style>